




























































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InventoriesTable from '@/components/InventoriesTable.vue'
import { ApiResponse, Pagination, Project } from '@/interfaces'
import { mapState } from 'vuex'
import QuickNav from '@/components/QuickNav.vue'

interface SortObject {
  order: string
  prop: string
}

interface Parameter {
  search?: string | null
  page?: string | null
}

interface DropdownAction {
  action: string
}

export default Vue.extend({
  components: {
    InventoriesTable,
    Nav,
    QuickNav,
  },
  data() {
    return {
      projects: [] as Project[],
      emptyProjects: [] as Project[],
      emptyProject: null as number | null,
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      busy: false,
      hasFilter: false,
      structures: [],
      structureFilter: null as number | null,
      natures: [],
      natureFilter: null as string | null,
      entryPeriod: [] as string[],
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
    statusFilters() {
      return [
        { text: 'En cours', value: 'en_cours' },
        { text: 'Clos', value: 'clos' },
      ]
    },
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client.structures
      this.natures = newVal.client.config.natures
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
      this.natures = this.user.client.config.natures
    }

    if (this.$route.query.search && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
      this.hasFilter = true
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.structure && this.$route.query.structure !== '') {
      this.structureFilter = parseInt(this.$route.query.structure as string)
      this.hasFilter = true
    }

    if (this.$route.query.nature && this.$route.query.nature !== '') {
      this.natureFilter = this.$route.query.nature as string
      this.hasFilter = true
    }

    if (this.$route.query.period) {
      this.entryPeriod = this.$route.query.period as string[]
      this.hasFilter = true
    }

    this.getProjects()
  },
  methods: {
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    updateRoute(resetPage = false) {
      if (resetPage) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        structure: this.structureFilter,
        nature: this.natureFilter,
        period: this.entryPeriod,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    sortChange(sort: SortObject) {
      this.order = null
      this.orderBy = null

      switch (sort.order) {
        case 'ascending':
          this.order = 'ASC'
          break

        case 'descending':
          this.order = 'DESC'
          break

        default:
          this.order = 'DESC'
          break
      }

      this.orderBy = sort.prop

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        structure: this.structureFilter,
        nature: this.natureFilter,
        period: this.entryPeriod,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getProjects() {
      if (!this.busy) {
        this.busy = true
        this.projects = [] as Project[]
        this.emptyProjects = [] as Project[]
        this.emptyProject = null

        const params = {} as Parameter
        if (this.search) {
          params.search = this.search
        } else {
          delete params.search
        }

        // Setting query URL
        this.$router
          .push({
            query: Object.assign({}, this.$route.query, params),
          })
          .catch(() => {
            // This empty catch method is here to avoid the "Avoided redundant navigation" error
          })

        const loading = this.$loading({
          target: '#inventoriesTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/inventories', {
            params: {
              search: this.search,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
              structure: this.structureFilter,
              nature: this.natureFilter,
              period: this.entryPeriod,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.projects = apiResponse.data.inventories
            this.emptyProjects = apiResponse.data.emptyInventories

            const pagination = apiResponse.data.pagination as Pagination

            this.activePage = pagination.current
            this.itemsTotal = pagination.totalCount
            this.numPages = pagination.pageCount
          })
          .finally(() => {
            loading.close()
            this.busy = false

            // Automatically scroll to stored scroll position
            window.setTimeout(() => {
              window.scrollTo({ top: parseInt(this.scrollPosition) })
            }, 400)
          })
      }
    },
    handleInventoriesCommand(command: DropdownAction) {
      if (command.action == 'export') {
        this.generateExport()
      }
    },
    generateExport() {
      this.$api
        .get(`/inventory/export-csv`, {
          params: {
            search: this.search,
            structure: this.structureFilter,
            nature: this.natureFilter,
            period: this.entryPeriod,
          },
        })
        .then(() => {
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/inventory-export/${this.user.client.id}/download`
          window.open(documentUrl, '_blank')
        })
    },
    editInventory(projectId: number | undefined, projects = [] as Project[]) {
      if (projectId) {
        if (projects.length === 0) {
          projects = this.projects
        }
        let project = projects.find((project) => project.id === projectId)
        this.$router.push({
          name: 'inventory_edit',
          params: {
            projectId: projectId.toString(),
            inventoryId: project?.inventory?.id ? project.inventory.id.toString() : '',
          },
        })
      }
    },
    getEmptyProjectLabel(project: Project): string {
      const generatedNumber = project.generatedNumber !== null ? project.generatedNumber + ' : ' : ''
      const name = project.createdBy?.formName ? `de ${project.createdBy.formName}` : "d'un utilisateur inconnu"
      const date = project.createdAt ? `le ${this.formatDate(project.createdAt, true)}.` : 'à une date inconnue.'

      return `${generatedNumber}${project.inventory?.numItems} lots transmis par l'application mobile ${name}, ${date}`
    },
  },
})
